<template>
	<ElSkeleton
		:loading="loading"
		:animated="animated"
		:class="['n8n-loading', `n8n-loading-${variant}`]"
	>
		<template #template>
			<div v-if="variant === 'h1'">
				<div
					v-for="(item, index) in rows"
					:key="index"
					:class="{
						[$style.h1Last]: item === rows && rows > 1 && shrinkLast,
					}"
				>
					<ElSkeletonItem :variant="variant" />
				</div>
			</div>
			<div v-else-if="variant === 'p'">
				<div
					v-for="(item, index) in rows"
					:key="index"
					:class="{
						[$style.pLast]: item === rows && rows > 1 && shrinkLast,
					}"
				>
					<ElSkeletonItem :variant="variant" />
				</div>
			</div>
			<div v-else-if="variant === 'custom'" :class="$style.custom">
				<ElSkeletonItem />
			</div>
			<ElSkeletonItem v-else :variant="variant" />
		</template>
	</ElSkeleton>
</template>

<script lang="ts">
import { ElSkeleton, ElSkeletonItem } from 'element-plus';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'N8nLoading',
	components: {
		ElSkeleton,
		ElSkeletonItem,
	},
	props: {
		animated: {
			type: Boolean,
			default: true,
		},
		loading: {
			type: Boolean,
			default: true,
		},
		rows: {
			type: Number,
			default: 1,
		},
		shrinkLast: {
			type: Boolean,
			default: true,
		},
		variant: {
			type: String,
			default: 'p',
			validator: (value: string): boolean =>
				[
					'custom',
					'p',
					'text',
					'h1',
					'h3',
					'text',
					'caption',
					'button',
					'image',
					'circle',
					'rect',
				].includes(value),
		},
	},
});
</script>

<style lang="scss" module>
.h1Last {
	width: 40%;
}
.pLast {
	width: 61%;
}
.custom {
	width: 100%;
	height: 100%;
}
</style>

<style lang="scss">
.n8n-loading-custom.el-skeleton {
	&,
	.el-skeleton__item {
		width: 100%;
		height: 100%;
	}
}
</style>
